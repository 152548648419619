import type {
	PaymentMethodCategories, PaymentMethodCategory,
	PaymentMethodCode,
} from '../../core/types';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

export type CategoriesDTO = Response<
	Array<{
		id: string;
		name: string;
		code: 'bankovskie_karty' | 'elektronnye_koshelki' | 'kriptovalyuta';
		items: {
			id: string;
			name: string;
			freekassa_id: string;
			currency: string;
			pic: string;
			validation_type: {
				code: string;
				name: string;
				placeholder: string;
				hint: string;
			} | null;
			max_sum: number;
			min_sum: number;
		}[];
	}>
>;

const codeMap: Record<
	CategoriesDTO['result'][number]['code'],
	PaymentMethodCode
> = {
	bankovskie_karty: 'bankCard',
	elektronnye_koshelki: 'electroWallet',
	kriptovalyuta: 'crypto',
};

export const toDomain = (data: CategoriesDTO): PaymentMethodCategories =>
	data.result.map((category): PaymentMethodCategory => {
		return {
			id: category.id,
			code: codeMap[category.code],
			name: category.name,
			items: category.items.map(item => ({
				id: item.id,
				code: item.freekassa_id,
				name: item.name,
				image: item.pic,
				currency: item.currency,
				validation: item.validation_type
					? {
							code: item.validation_type.code,
							name: item.validation_type.name,
							placeholder: item.validation_type.placeholder,
							message: item.validation_type.hint,
						}
					: null,
				minSum: item.min_sum,
				maxSum: item.max_sum,
			})),
		};
	});

export const useGetCategories = () => {
	const api = useApi();

	return async () =>
		(await api<CategoriesDTO>('/Paysystems/get/')).mapRight(toDomain);
};
