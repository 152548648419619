import { paymentMethodApi } from '../../infrastructure/api';
import {
	readPaymentMethodsCategories,
	savePaymentMethodsCategories,
} from '../../infrastructure/store';

export const useGetCategories = () => {
	const getCategories = paymentMethodApi.useGetCategories();

	return async () => {
		const isCategoriesLoaded = !!readPaymentMethodsCategories();

		if (!isCategoriesLoaded) {
			const result = await getCategories();

			if (result.isRight()) {
				savePaymentMethodsCategories(result.value);
			}
		}
	};
};
